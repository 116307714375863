import {configure, computed, spy, makeObservable} from 'mobx';

import analytics from 'analytics.js';
import autobind from 'common/decorators/autobind.js';
import {getCopyData} from 'core/referrals.js';
import {
    PRACTICE_LOC_CONTACT_TYPE,
    PAGE_TYPE_PRACTICE_LOCATION,
} from 'core/constants.js';
import PracticeStore from 'practices/stores/PracticeStore.js';

// Throws if observable is changed outside of action
configure({enforceActions: 'observed'});

export default class PracticeLocationStore extends PracticeStore {
    constructor(rootStore) {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        this.rootStore = rootStore;

        makeObservable(this, {
            header: computed,
            contactProps: computed,
        });
    }

    isPracticeGroup = false;
    pageType = PAGE_TYPE_PRACTICE_LOCATION;

    get header() {
        return {
            avatar: this.practice.avatar,
            amountOfLocations: this.practice.amountOfLocations,
            languagesSpoken: this.practice.languagesSpoken,
            streetAddress: this.practice.streetAddress,
            name: this.practice.name,
            practiceGroupLink: this.practice.practiceGroupLink,
            locality: this.practice.locality,
            phones:
                this.practice.phones?.length > 0
                    ? {
                          numbers: this.practice.phones,
                      }
                    : undefined,
            fax: this.practice.fax
                ? {
                      ...this.practice.fax,
                  }
                : undefined,
            website: this.practice.website
                ? {
                      analyticsEventData: {
                          eventName: 'openPracticeLocationWebsite',
                          data: {
                              'practice_location': this.practice.id,
                          },
                      },
                      href: this.practice.website,
                  }
                : undefined,
        };
    }

    get contactProps() {
        const {practice} = this;
        if (this.practiceLoaded && practice.phones?.length > 0) {
            return {
                contactId: practice.id,
                contactName: practice.name,
                contactType: PRACTICE_LOC_CONTACT_TYPE,
                isEnhanced: practice.enhanced,
                items: [
                    {
                        address: `${practice.streetAddress}, ${practice.locality}`,
                        id: practice.id,
                        name: practice.name,
                        phones: practice.phones,
                        type: PRACTICE_LOC_CONTACT_TYPE,
                    },
                ],
            };
        }
        return null;
    }

    // Extracts data from an available practice and adds a profile detail if provided
    @autobind
    getCopyData(profileName, additionalProps) {
        return getCopyData(profileName, {
            ...this.practice,
            ...additionalProps,
        });
    }

    @autobind
    throwFetchPracticeError(error) {
        throw new Error(`Practice Location API returned: ${error}`);
    }

    trackPageOpen() {
        analytics.track('openPracticeLocation', {
            practiceLocationId: this.practice.id,
            practiceGroupId: this.practice.practiceGroupId,
        });
    }

    fetchOpenFileData(fileId) {
        if (fileId) {
            return {
                eventName: 'openPracticeLocationFile',
                data: {
                    'practice_location_file': fileId,
                },
            };
        } else {
            this.throwError(
                'Missing required file id for tracking openPracticeLocationFile',
            );
        }
    }
}
