import {configure, computed, makeObservable} from 'mobx';

import autobind from 'common/decorators/autobind.js';
import {
    PRACTICE_GRP_CONTACT_TYPE,
    PRACTICE_LOC_CONTACT_TYPE,
    PAGE_TYPE_PRACTICE_GROUP,
} from 'core/constants.js';
import {getCopyData} from 'core/referrals.js';
import {TELEHEALTH_ONLY} from 'core/telehealth.js';
import PracticeStore from 'practices/stores/PracticeStore.js';

// Throws if observable is changed outside of action
configure({enforceActions: 'observed'});

export default class PracticeGroupStore extends PracticeStore {
    constructor(rootStore) {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        this.rootStore = rootStore;

        makeObservable(this, {
            header: computed,
            contactProps: computed,
            telehealthLocations: computed,
        });
    }

    isPracticeGroup = true;
    pageType = PAGE_TYPE_PRACTICE_GROUP;
    get header() {
        return {
            avatar: this.practice.avatar,
            name: this.practice.name,
            phones:
                this.practice.phones?.length > 0
                    ? {
                          numbers: this.practice.phones,
                      }
                    : undefined,
            fax: this.practice.fax && this.practice.fax,
            website: this.practice.website
                ? {
                      analyticsEventData: {
                          eventName: 'openPracticeGroupWebsite',
                          data: {
                              'practice_group': this.practice.id,
                          },
                      },
                      href: this.practice.website,
                  }
                : undefined,
        };
    }

    get contactProps() {
        if (!this.practiceLoaded) {
            return null;
        }
        const props = {
            contactId: this.practice.id,
            contactName: this.practice.name,
            contactType: PRACTICE_GRP_CONTACT_TYPE,
            isEnhanced: this.practice.enhanced,
            // All the practice's location plus the practice itself
            items: [],
        };

        if (this.practice.phones?.length > 0) {
            props.items.push({
                id: this.practice.id,
                name: this.practice.name,
                phones: this.practice.phones,
                type: PRACTICE_GRP_CONTACT_TYPE,
            });
        }

        if (this.practice.locations?.length > 0) {
            this.practice.locations.forEach((location) => {
                if (location.phones?.length > 0) {
                    props.items.push({
                        address: location.address,
                        id: location.id,
                        localityName: location.city,
                        localityState: location.state,
                        name: location.name,
                        phones: location.phones,
                        practiceUrl: location.practiceUrl,
                        type: PRACTICE_LOC_CONTACT_TYPE,
                    });
                }
            });
        }

        if (props.items.length > 0) {
            return props;
        } else {
            return null;
        }
    }

    get telehealthLocations() {
        return this.practice.locations?.filter(
            (loc) => loc.offersTelehealth === TELEHEALTH_ONLY,
        );
    }

    // Extracts data from an available practice and adds a profile detail if provided
    @autobind
    getCopyData(profileName, location) {
        return getCopyData(profileName, location);
    }

    // Extracts data from a passed in location object
    getCopyDataForLocation({
        fax,
        street1,
        street2,
        city,
        postCode,
        state,
        phones,
        name,
    }) {
        const bits = [];
        if (name) {
            bits.push(name);
        }
        if (street1) {
            bits.push(street1);
        }
        if (street2) {
            bits.push(street2);
        }
        bits.push(`${city} ${state} ${postCode}`);
        if (phones?.length) {
            bits.push(`Phone: ${phones[0].number}`);
        }
        if (fax?.number) {
            bits.push(`Fax: ${fax.number}`);
        }
        return bits.join('\r\n');
    }

    @autobind
    throwFetchPracticeError(error) {
        throw new Error(`Practice Group API returned: ${error}`);
    }

    trackPageOpen() {
        this.rootStore.analyticsStore.trackEvent('openPracticeGroup', {
            practiceGroupName: this.practice.name,
            practiceGroupId: this.practice.id,
        });
    }

    fetchOpenFileData(fileId) {
        if (fileId) {
            return {
                eventName: 'openPracticeGroupFile',
                data: {
                    'practice_group_file': fileId,
                },
            };
        } else {
            this.throwError(
                'Missing required file id for tracking openPracticeGroupFile',
            );
        }
    }
}
