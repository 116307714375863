import {
    configure,
    computed,
    observable,
    when,
    makeObservable,
    reaction,
} from 'mobx';
import React, {createContext} from 'react';
import ReactDOM from 'react-dom';

import http from 'http.js';
import {MOBILE_MAX_SCREEN_SIZE, TABLET_MAX_SCREEN_SIZE} from 'base/search.js';
import {PAGE_TYPE_PRACTICE_LOCATION} from 'core/constants.js';
import autobind from 'common/decorators/autobind.js';
import ContactModal from 'core/components/ContactModal.js';
import ReferralModal from 'core/components/ReferralModal.js';
import ImageGalleryModal from 'core/components/ImageGalleryModal.js';
import Modal from 'core/components/Modal.js';
import Store from 'core/stores/Store.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';
import {toggleLoader} from 'core/utils.js';

// Throws if observable is changed outside of action
configure({enforceActions: 'observed'});

export default class PracticeStore extends Store {
    constructor(rootStore) {
        super();
        this.rootStore = rootStore;

        makeObservable(this, {
            activeTab: observable,
            customModalProps: observable,
            displayedModal: observable,
            gapSchemeMembership: observable,
            url: observable,
            practice: observable,
            practiceFAQs: observable,
            practiceRelatedContent: observable,
            practiceLoaded: observable,
            practiceRelatdContentLoaded: observable,

            displayTabsOnPage: computed,
            tabs: computed,
        });

        when(
            () => this.url,
            () => this.fetchPracticeInfo(),
        );
        when(
            () => this.url,
            () => this.fetchPracticeRelatedContent(),
        );
        when(
            () => this.url,
            () => this.fetchPracticeFAQs(),
        );
        when(
            () => this.practiceLoaded,
            () => this.trackPageOpen(),
        );

        reaction(
            () =>
                !this.isHospitalPage &&
                this.practiceLoaded &&
                this.rootStore?.healthFundStore.healthFund?.id,
            () => {
                this.fetchGapSchemeMembership();
            },
        );

        reaction(
            () => this.displayTabsOnPage && this.practiceLoaded,
            () => {
                this.setActiveTab();
                this.fetchActiveTab();
            },
        );
        reaction(
            () => this.isMobileOrTablet,
            () => this.setActiveTab(),
        );
        reaction(
            () => this.practiceLoaded,
            (loaded) => {
                toggleLoader(loaded);
            },
        );
    }

    overviewTab = {
        title: 'Overview',
        hash: 'overview',
        component: 'PracticeOverview',
    };
    relatedInfoTab = {
        title: 'Related info',
        hash: 'related-info',
        component: 'PracticeRelatedInfo',
    };
    faqTab = {
        title: 'FAQ',
        hash: 'faq',
        component: 'PracticeFAQ',
    };

    activeTab = null;
    client = undefined;
    customModalProps = {};
    displayedModal = undefined;
    isPracticeGroup = false;
    gapSchemeMembership = [];
    url = null;
    practice = {};
    practiceFAQs = [];
    practiceRelatedContent = {};
    practiceLoaded = false;
    practiceRelatdContentLoaded = false;
    url = null;

    displayToolsOnPage = true;

    @autobind
    closeModal() {
        this.updateStore({
            customModalProps: {},
            displayedModal: null,
        });
    }

    @autobind
    showModal(modalType, modalProps) {
        this.updateStore({
            customModalProps: modalProps,
            displayedModal: modalType,
        });
    }

    @autobind
    async fetchGapSchemeMembership() {
        const healthFund = this.rootStore.healthFundStore.healthFund;
        if (healthFund?.id) {
            let data =
                (await http.post({
                    url: `/api/professionals/v1/gap-scheme-membership/health-fund/${healthFund.id}`,
                    data: {userprofile_ids: this.practitionerIds},
                })) || [];
            data = data.map((d) => snakeToCamelObjectKeys(d));
            this.updateStore({gapSchemeMembership: data});
        }
    }

    @autobind
    shouldShowPractitionersTab() {
        return (
            this.rootStore.paramStore.isClient &&
            this.practice.practitioners?.length
        );
    }

    get practitionerIds() {
        return this.practice.practitioners?.map((pr) => pr.userprofileId);
    }

    get displayTabsOnPage() {
        return true;
    }

    get tabs() {
        const displayTabs = [];
        if (this.practiceLoaded) {
            if (
                this.practice.tagline ||
                this.practice.tagline2 ||
                this.practice.description ||
                this.practice.files?.length ||
                (!this.rootStore.paramStore.isClient &&
                    (this.practice.images?.length ||
                        this.practice.practitioners?.length)) ||
                (window.matchMedia(`(max-width: ${MOBILE_MAX_SCREEN_SIZE})`)
                    .matches &&
                    this.practice.extraInfo &&
                    Object.values(this.practice.extraInfo).length)
            ) {
                displayTabs.push(this.overviewTab);
            }
            if (
                !this.rootStore.paramStore.isClient &&
                !this.practice.enhanced &&
                !this.practice.premiumPartner &&
                Object.keys(this.practiceRelatedContent).length > 0
            ) {
                displayTabs.push(this.relatedInfoTab);
            }
            if (this.practiceFAQs.length) {
                displayTabs.push(this.faqTab);
            }
            if (
                this.practice.locations?.length &&
                this.isPracticeGroup &&
                !this.rootStore.paramStore.isClient
            ) {
                const locationsTab = {
                    title: 'Locations',
                    hash: 'locations',
                    component: 'LocationList',
                    count: this.practice.locations.length,
                };
                const firstTab = displayTabs.shift();
                displayTabs.unshift(firstTab, locationsTab);
            }
            if (this.shouldShowPractitionersTab()) {
                const practitionersTab = {
                    title: 'Practitioners',
                    hash: 'practitioners',
                    component: 'PractitionerList',
                    count: this.practice.practitioners.length,
                };
                displayTabs.splice(1, 0, practitionersTab);
            }
        }
        return displayTabs;
    }

    @autobind
    async selectHealthFundHandler(hf) {
        await this.rootStore.healthFundStore.selectHealthFund(hf?.id || 0);

        if (this?.isHospitalPage) {
            const hospitalId = parseInt(
                window.sessionStorage.getItem('hospitalId'),
            );
            if (this.practice.id !== hospitalId) {
                window.sessionStorage.setItem('hospitalId', this.practice.id);
            }
            window.location.reload();
        }
    }

    @autobind
    renderModal() {
        if (!this.practiceLoaded) {
            return null;
        }
        let ModalContent;
        let qs = document.querySelector('#practice-group');
        if (!qs) {
            qs = document.querySelector('#practice-location');
        }
        if (!qs) {
            qs = document.querySelector('#hospital-page');
        }
        switch (this.displayedModal) {
            case 'hospitalContact':
                ModalContent = (
                    <ContactModal
                        {...this.customModalProps}
                        closeModal={this.closeModal}
                        isHospitalDirectory={true}
                    />
                );

                break;
            case 'contact':
                ModalContent = (
                    <ContactModal
                        {...this.contactProps}
                        analytics={this.analytics}
                        closeModal={this.closeModal}
                        {...this.customModalProps}
                    />
                );
                break;
            case 'gallery':
                ModalContent = (
                    <ImageGalleryModal
                        closeModal={this.closeModal}
                        images={this.practice.images}
                        {...this.customModalProps}
                    />
                );
                break;
            case 'referralModal':
                ModalContent = (
                    <ReferralModal
                        closeModal={this.closeModal}
                        {...this.customModalProps}
                    />
                );
                break;
            default:
                throw new Error('Cannot render an invalid modal type');
        }
        return ReactDOM.createPortal(
            <Modal
                closeModal={this.closeModal}
                initElement={document.activeElement}
                parentElement={document.activeElement.parentElement}
            >
                {ModalContent}
            </Modal>,
            qs,
        );
    }

    @autobind
    setActiveTab() {
        if (
            this.rootStore.growthBookStore?.experimentInfo
                ?.redesignDirectoryPlUp &&
            this.pageType === PAGE_TYPE_PRACTICE_LOCATION
        ) {
            // Redesigned PL page shouldn't use this logic
            return null;
        }
        const activeTab = this.fetchDefaultTab();
        const fallbackHash = activeTab?.hash.length
            ? `#${activeTab.hash}`
            : '';
        this.updateStore({activeTab: activeTab});
        this.replaceHashWith(fallbackHash);
    }

    @autobind
    fetchDefaultTab() {
        let activeTab = null;
        if (this.shouldShowPractitionersTab() && !this.isHospitalPage) {
            activeTab = this.tabs.find((tab) => tab.hash === 'practitioners');
        } else if (this.tabs.length) {
            activeTab = this.tabs[0];
        }
        return activeTab;
    }

    @autobind
    fetchActiveTab() {
        const tabHash = window.location.hash.split('#')[1];
        const activeTab = this.tabs.find((tab) => tab.hash === tabHash);
        if (tabHash && activeTab) {
            this.updateStore({activeTab});
            this.replaceHashWith(`#${activeTab.hash}`);
        } else {
            this.setActiveTab();
        }
    }

    @autobind
    onWindowResize() {
        const isMobileOrTablet = window.matchMedia(
            `(max-width: ${TABLET_MAX_SCREEN_SIZE})`,
        ).matches;
        this.updateStore({isMobileOrTablet});
        if (!isMobileOrTablet && this.activeTab.hash === 'locations') {
            this.setActiveTab();
        }
    }

    replaceHashWith(value) {
        if (value) {
            history.replaceState('', '', value);
        } else {
            history.replaceState(
                '',
                document.title,
                location.pathname + location.search,
            );
        }
    }

    @autobind
    async fetchPracticeInfo() {
        let practice = {};
        try {
            const response = await http.get({
                url: this.url,
                data: {
                    show: this.rootStore.paramStore.showParam,
                },
            });
            practice = snakeToCamelObjectKeys(response);
        } catch (error) {
            this.throwFetchPracticeError(error);
        } finally {
            this.updateStore({practice, practiceLoaded: true});
        }
    }

    @autobind
    async fetchPracticeRelatedContent() {
        let practiceRelatedContent = {};
        let response;
        try {
            response = await http.get({
                url: `${this.url}related-content/`,
            });
        } catch (error) {
            this.throwRelatedContentError(error);
        } finally {
            // replacement to .flat() which doesn't work on older browsers.
            if (response) {
                const data = Object.values(response);
                if ([].concat(...Object.values(data)).length) {
                    practiceRelatedContent = snakeToCamelObjectKeys(response);
                }
            }
            this.updateStore({
                practiceRelatedContent,
                practiceRelatedContentLoaded: true,
            });
        }
    }

    @autobind
    async fetchPracticeFAQs() {
        let practiceFAQs = [];
        try {
            const responseList = await http.get({
                url: `${this.url}faqs/`,
            });
            practiceFAQs = responseList.map((res) =>
                snakeToCamelObjectKeys(res),
            );
        } catch (error) {
            this.throwFAQError(error);
        } finally {
            this.updateStore({practiceFAQs});
        }
    }

    @autobind
    throwRelatedContentError(error) {
        throw new Error(`Fetching practice related content failed ${error}`);
    }

    @autobind
    throwFAQError(error) {
        throw new Error(`Fetching practice FAQs failed ${error}`);
    }

    @autobind
    throwFetchPracticeError(error) {
        throw new Error(`Fetching practice failed ${error}`);
    }
}
