/**
 * Internal event tracking libraries for practice location page
 */
import analytics from 'analytics.js';

export async function trackOpenPracticeLocation(practice) {
    return analytics.track('openPracticeLocation', {
        practiceLocationId: practice?.id,
        practiceGroupId: practice?.practiceGroupId,
    });
}

export async function trackOpenPracticeLocationFile(practiceLocationFileId) {
    return analytics.track('openPracticeLocationFile', {
        practice_location_file: practiceLocationFileId,
    });
}

export async function trackPracticeLocationFaxClick(practice, phoneNumber) {
    return analytics.track('practiceLocationFaxClick', {
        practiceLocationId: practice?.id,
        phoneNumber: phoneNumber,
    });
}

export async function trackOpenPracticeLocationWebsite(practice, website) {
    return analytics.track('openPracticeLocationWebsite', {
        practice_location: practice?.id,
        website: website,
    });
}
